<template>
  <v-container class="grey lighten-5 mb-6" fluid>
    <v-row>
      <v-col class="main-col-margin">
        <v-card class="mx-auto">
          <v-card-title
            class="main-card-title-offset"
          >
            <v-icon large left color="white"> mdi-format-list-bulleted </v-icon>
            <span class="title">
              {{ $t('user.list.title') }}
            </span>
            <v-spacer></v-spacer>

            <v-btn icon @click="createUser()">
              <v-icon color="white">mdi-account-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="users"
            :sort-by="[]"
            :sort-desc="[false, true]"
            multi-sort
            class="elevation-1"
            :loading="isLoading"
            :loading-text="$t('user.list.loading')"
          >
          
            <template v-slot:[`item.type`]="props">
              {{ userTypeToLabel(props.item.type) }}
            </template>
            <template v-slot:[`item.controls`]="props">
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="warning"
                @click="editUser(props.item)"
              >
                <v-icon dark>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="error"
                @click="openDeleteUserDialog(props.item)"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">{{ $t('user.list.delete.title') }}</v-card-title>
          <v-card-text></v-card-text>
          <v-card-actions class="justify-center">
            <v-row class="text-center" style="margin:0px">
              <v-col >
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="error"
                  text
                  @click="deleteUser()"
                >
                  <v-icon dark>mdi-delete</v-icon>
                  {{ $t('user.list.delete.controlls.delete') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  text
                  @click="closeDeleteUserDialog()"
                >
                  <v-icon dark>mdi-close-circle</v-icon>
                  {{ $t('user.list.delete.controlls.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <error-handler :error="error"></error-handler>
  </v-container>
</template>
<script>
export default {
  name: "ListUsers",
  data() {
    return {
      isLoading: true,
      headers: [
        { text: this.$t('user.list.label.fullname'), align: "start", value: "fullname" },
        { text: this.$t('user.list.label.email'), value: "email" },
        { text: this.$t('user.list.label.vendorName'), value: "vendor.name" },
        { text: this.$t('user.list.label.type'), value: "type" },
        {
          text: this.$t('user.list.label.actions'),
          value: "controls",
          sortable: false,
          align: "center",
        },
      ],
      users: [],
      dialog: false,
      deletingUser: null,
      error: null,
    };
  },
  computed: {
    applicationData() {
      return this.$store.state.applicationData
    },
    userTypes() {
      if(this.applicationData) {
        return this.applicationData.user_types; 
      }
      return []
    }
  },
  mounted() {
    this.$store.dispatch('loadApplicationData')
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      this.$axios({
        method: "get",
        url: this.$globals.api.user.getAll,
      })
        .then((response) => {
          this.users = response.data.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
    createUser() {
      this.$router.push({ name: "user/add" });
    },
    editUser(user) {
      this.$router.push({ name: "user/edit", params: { id: user.id } });
    },
    openDeleteUserDialog(userData) {
      this.deletingUser = userData
      this.dialog = true
    },
    closeDeleteUserDialog() {
      this.deletingUser = null
      this.dialog = false;
    },
    deleteUser() {
      this.$axios({
        method: "post",
        url: this.$globals.api.user.delete + this.deletingUser.id,
      })
        .then(() => {
          this.deletingUser = null
          this.dialog = false
          this.isLoading = true
          this.fetchUsers()
        })
        .catch((error) => {
          this.error = error.response;
        });
    }, 
    countriesArrayToString(array) {
      let iso_codes = []

      array.forEach(function (rating) {
        iso_codes.push(rating.iso)
      });

      return iso_codes.join(",")
    },
    userTypeToLabel(type) {
      let label = '';

      this.userTypes.forEach((types) => {
        if(types['value'] == type)
          label = types['label'];
      });

      return label;
    },
    getUserSetupData() {
      this.$axios({
        method: "get",
        url: this.$globals.api.general.user.data,
      })
        .then((response) => {
          this.userTypes = response.data.data.user_types;
        })
        .catch((error) => {
          this.error = error.response;
        });
    },
  },
};
</script>